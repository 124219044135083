import React from "react";
import Header from "./Header/Header";
import Footer from "./footer";
import "../App.css";
import { useEffect } from "react";
import { useRef } from "react";
function Music() {

  useEffect(() => {
    if (!document.querySelector("header").classList.contains("headerStick")) {
      document.querySelector("header").classList.add("headerStick");
    }
    window.scroll(0, 0);
  }, []);

  return (
    <div className="AppArt mus" style={{ alignItems: "center" }}>
      <Header />
      <div
        className="content"
        style={{
          justifyContent: "flex-start",
          marginTop: "1em",
          marginBottom: "20px",
          flex: "1 0 auto",
        }}
      >
        {/* soundcloud and bandcamp icons */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <a
            style={{ marginRight: "20px" }}
            href="https://soundcloud.com/qareebi"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://img.icons8.com/ios-filled/50/ffffff/soundcloud.png"
              alt="soundcloud"
            />
          </a>
          <a
            href="https://madebyio.bandcamp.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://img.icons8.com/ios-filled/50/ffffff/bandcamp.png"
              alt="bandcamp"
            />
          </a>
        </div>

        <iframe
          className="bandcamp"
          title="bandcamp-1"
          src="https://bandcamp.com/EmbeddedPlayer/album=1592178679/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/track=3587631893/transparent=true/"
          seamless
        >
          <a href="https://krunkkulture.bandcamp.com/album/ganga-jamuna-flavours-of-the-east">
            Ganga Jamuna - Flavours Of The East by IO
          </a>
        </iframe>

        <iframe
          title="bandcamp-2"
          className="bandcamp"
          src="https://bandcamp.com/EmbeddedPlayer/album=2740240536/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/track=1306899744/transparent=true/"
          seamless
        >
          <a href="https://taabiir.bandcamp.com/album/dear-craving-chirp-remix-ep">
            Dear Craving/Chirp Remix EP by Hedrun
          </a>
        </iframe>
        <iframe
          className="bandcamp"
          title="bandcamp-3"
          src="https://bandcamp.com/EmbeddedPlayer/album=1902056026/size=large/bgcol=ff0000/linkcol=0687f5/artwork=small/transparent=true/"
          seamless
        >
          <a href="https://madebyio.bandcamp.com/album/taomatiog-drowning-extensions">
            TAOMATIOG + Drowning Extensions by IO
          </a>
        </iframe>

        <iframe
          className="bandcamp"
          title="bandcamp-4"
          src="https://bandcamp.com/EmbeddedPlayer/track=2984058013/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/"
          seamless
        >
          <a href="https://madebyio.bandcamp.com/track/the-mask">
            The Mask by IO
          </a>
        </iframe>
        <iframe
          title="bandcamp-5"
          className="bandcamp"
          src="https://bandcamp.com/EmbeddedPlayer/album=1251016487/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/track=3020422304/transparent=true/"
          seamless
        >
          <a href="https://22-2.bandcamp.com/album/va-22-2">VA 22-2 by 22-2</a>
        </iframe>
        <iframe
          title="sc-6"
          className="bandcamp"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1272000493&color=%23ff0000&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"
        />
        <iframe
          title="sc-7"
          className="bandcamp"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1001401264&color=%23ff0000&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"
        />

        <iframe
          title="sc-8"
          className="bandcamp"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/879814654&color=%23ff0000&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"
        />

        <div
          style={{
            fontSize: "1.5em",
            marginTop: "15px",
            marginBottom: "15px",
            color: "#ffffff",
          }}
        >
          Mixes
        </div>

        <iframe
          title="mx-9"
          className="bandcamp"
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fqareebi%2Fqareebi-mix-one%2F"
          frameborder="0"
        ></iframe>
        <iframe
          title="mx-10"
          className="bandcamp"
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fkrunklive%2Fkrunk-guest-mix-134-io%2F"
          frameborder="0"
        ></iframe>
      </div>
      <Footer />
    </div>
  );
}

export default Music;
