import React from "react";
import Header from "./Header/Header";
import Footer from "./footer";
import { useEffect } from "react";
import "../App.css";
function Tech() {
  useEffect(() => {
    if (!document.querySelector("header").classList.contains("headerStick")) {
      document.querySelector("header").classList.add("headerStick");
    }
    window.scroll(0, 0);
  }, []);

  return (
    <div className="AppArt tec" style={{ alignItems: "center" }}>
      <Header />
      <div className="techinner techw" style={{ flex: "1 0 auto" }}>
        <h1 className="r-black">Syed Ali</h1>
        <h5>21, Bangalore, India</h5>
        <h3
          className="r-regular"
          style={{ marginTop: "1em", marginBottom: "0.3em" }}
        >
          Bachelor of Computer Science Engineering,
          <br />
          Ramaiah University, Bangalore, 2020-2024
        </h3>
        <h3
          className="r-black"
          style={{
            marginTop: "0.5em",
            marginBottom: "0.3em",
            fontSize: "1.75em",
          }}
        >
          Experience
        </h3>
        <p className="techtext r-medium">
          <ul>
            <li>
              <div className="dur">
                AI & ML Developer at{" "}
                <a
                  href="https://beatoven.ai"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  beatoven.ai
                </a>
                , <br />
                June, 2021 - Present
              </div>
              <div className="jd">
                Affective Classification (Audio & Video), AI Music Composition &
                Generation, DSP, Computer Vision, Western Music Theory <br />
                WebApps: ReactJS, Flask, Celery, Docker & AWS Suite
              </div>
            </li>
            <li>
              <div className="dur">
                Graphic Designer, Independent <br />
                August, 2019 - Present
              </div>
              <div className="jd">
                Multimedia, Typography, Album Arts, Branding & Identity,
                Generative AI Art (on Customized GANs & StableDiffusion) <br />
                Adobe CC Suite - Photoshop, Illustrator, After Effects &
                InDesign.
              </div>
            </li>
            <li>
              <div className="dur">
                Musician, Independent
                <br />
                December, 2018 - Present
              </div>
              <div className="jd">
                As Qareebi, Electronic & Ambient <br />
                Previously as IO (2018 - 2020)
              </div>
            </li>
          </ul>
        </p>
        <p></p>
      </div>
      <Footer />
    </div>
  );
}

export default Tech;
