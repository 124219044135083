import React from 'react'
import Header from './Header/Header'
import Footer from './footer';
import "../App.css";
import email from '../assets/email.png'
import linkedin from '../assets/linkedin.png'
import instagram from '../assets/ig.png'
import github from '../assets/github.png'
import cv from '../assets/cv.svg'

import { useEffect } from "react"
function About() {

    useEffect(() => {
        if (!document.querySelector("header").classList.contains("headerStick")) {
            document.querySelector("header").classList.add("headerStick");
        }
        window.scroll(0, 0);
    }, [])

    return (
        <div className="App con" style={{ alignItems: "center" }}>
            <Header />
            <div className="p">
                <div className='intro'>
                    <h1>
                        Hi, I'm Ali
                    </h1>
                    <p>
                        I am a musician, graphic designer and software engineer. "Qareebi" is the moniker of my current electronic music and visual art practice. I am based in Bangalore, India.
                    </p>
                </div>
                <div className='contactgroup'>
                    <div className='contact email'>
                        <a className='contactlogos' href='mailto:hi@qaree.bi'>
                            <img src={email} alt='email' />
                            hi@qaree.bi
                        </a>
                    </div>
                    <div className='contact instagram'>
                        <a className="contactlogos" href='https://instagram.com/qaree.bi'>
                            <img src={instagram} alt='instagram' />
                            instagram.com
                        </a>
                    </div>

                    <div className='contact linkedin'>
                        <a className="contactlogos" href='https://www.linkedin.com/in/basisvectors/'>
                            <img src={linkedin} alt='linkedin' />
                            linkedin
                        </a>
                    </div>
                    <div className='contact github'>
                        <a className='contactlogos' href='https://github.com/basisvectors/'>
                            <img src={github} alt='github' />
                            github
                        </a>
                    </div>
                    {/* <div className='contact CV'>
                        <a className="contactlogos" href="/SyedAliResume.pdf" target="_blank">
                            <img src={cv} alt='cv' />
                            cirriculum vitae
                        </a>
                    </div> */}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default About