import React from 'react'

function Footer() {
    return (
        <div className="footer">
            <ul>
                <li>
                    <a href="https://instagram.com/qaree.bi" target="_blank" style={{ color: "white" }} rel="noreferrer">instagram</a>
                </li>
                {/* <li>
                            <a href="https://twitter.com/qaree_bi" target="_blank" style={{ color: "white"}}>twitter</a>
                        </li> */}
            </ul>
            <h6
                style={{
                    color: "white",
                }}
            >
                © 2022 syed ali, designed by <a href="https://www.instagram.com/qaree.bi/" target="_blank" rel="noreferrer">qaree.bi</a><br />
                No cookies, no tracking, no ads, no analytics.
            </h6>
        </div>
    )
}

export default Footer